import React from 'react';

import Triangle from '../../components/Triangle';

const Background = () => (
  <div>
    <Triangle
      color="backgroundDark"
      height={['35vh', '80vh']}
      width={['95vw', '60vw']}
      invertX
    />

    <Triangle
      color="primary"
      height={['12vh', '14vh']}
      width={['75vw', '60vw']}
      invert
    />

    <Triangle
      color="backgroundDark"
      height={['60vh', '70vh']}
      width={['60vw', '60vw']}
      invertY
    />

    <Triangle
      color="secondaryLight"
      height={['10vh', '20vh']}
      width={['50vw', '50vw']}
      invertX
      invertY
    />
  </div>
);

export default Background;
