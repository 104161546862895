import styled from 'styled-components';

export const StoreLinks = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: row;
`;

export const StyledLink = styled.span`
  display: inline-block;
  transition: color 250ms, text-shadow 250ms;
  color: ${(props) => props.theme.colors.text};
  cursor: pointer;
  position: relative;
  text-decoration: none;

  &:after {
    position: absolute;
    z-index: -1;
    bottom: 1px;
    left: 50%;
    transform: translateX(-50%);
    content: '';
    width: 100%;
    height: 3px;
    background-color: ${(props) => props.theme.colors.primaryLight};
    transition: all 250ms;
  }

  &:hover {
    color: white;

    &::after {
      height: 110%;
      width: 110%;
    }
  }
`;
