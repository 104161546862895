import React from 'react';
import {Flex, Box, Image} from 'rebass/styled-components';
import {graphql, navigate} from 'gatsby';
import ReactMarkdown from 'react-markdown';
import Fade from 'react-reveal/Fade';

import markdownRenderer from '../../components/MarkdownRenderer';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import {Header, IconLink} from '../../components';

import Background from './background';
import {StoreLinks, StyledLink} from './styled';

export default function ProjectPageTemplate({data}) {
  const {
    name,
    slug,
    description,
    appStoreUrl,
    banner,
    playStoreUrl,
    websiteUrl,
    privacyPolicy,
  } = data.contentfulProject;

  return (
    <Layout>
      <Header />
      <Section.Container id="project" Background={Background}>
        <Box width={[1, 1, 2 / 3]} mt={[5]} ml="auto" mr="auto">
          <Flex style={{justifyContent: 'space-between'}}>
            <Section.Header name={name} />
            <Fade right>
              <StoreLinks>
                {appStoreUrl && (
                  <Box mx={1} fontSize={4}>
                    <IconLink
                      name="View on the App Store"
                      fontAwesomeIcon="apple"
                      url={appStoreUrl}
                      color="apple"
                      hoverColor="appleLight"
                    />
                  </Box>
                )}
                {playStoreUrl && (
                  <Box mx={1} fontSize={4}>
                    <IconLink
                      name="View on the Google Play Store"
                      fontAwesomeIcon="android"
                      url={playStoreUrl}
                      color="android"
                      hoverColor="androidLight"
                    />
                  </Box>
                )}
                {websiteUrl && (
                  <Box mx={1} fontSize={4}>
                    <IconLink
                      name="View their website"
                      fontAwesomeIcon="globe"
                      url={websiteUrl}
                    />
                  </Box>
                )}
              </StoreLinks>
            </Fade>
          </Flex>

          {banner && (
            <Box style={{textAlign: 'center'}}>
              <Fade bottom>
                <Image src={banner.image.src} alt={banner.title} />
              </Fade>
            </Box>
          )}

          <Box width={1} mt={4}>
            <Fade left>
              <ReactMarkdown
                source={description.childMarkdownRemark.rawMarkdownBody}
                renderers={markdownRenderer}
              />
            </Fade>
          </Box>
          {privacyPolicy && (
            <Box width={1} mt={4}>
              <Fade bottom>
                <StyledLink
                  onClick={() => navigate(`/projects/${slug}/privacy-policy`)}>
                  Privacy Policy
                </StyledLink>
              </Fade>
            </Box>
          )}
        </Box>
      </Section.Container>
    </Layout>
  );
}

export const query = graphql`
  query($slug: String!) {
    contentfulProject(slug: {eq: $slug}) {
      slug
      description {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      appStoreUrl
      playStoreUrl
      websiteUrl
      name
      banner {
        title
        image: resize(width: 1200, quality: 100) {
          src
        }
      }
      privacyPolicy {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
    }
  }
`;
